








import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useContent } from "~/composables";
import useStoryblok from "~/composables/useStoryblok";

export default defineComponent({
  name: "HomePage",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();

    const storyblokData = ref(null);
    const { getStoryblokData } = useStoryblok();

    const metatags = ref(null);

    const page = ref(null);
    useFetch(async () => {
      page.value = await loadPage({ identifier: "home" });

      await getStoryblokData("home").then((res) => {
        storyblokData.value = res;
        metatags.value = res.story.content.metatags;
      });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: "home" }]);
    });

    // @ts-ignore
    return {
      page,
      storyblokData,
      metatags,
    };
  },
  head() {
    return getMetaInfo(this.metatags);
  },
});
